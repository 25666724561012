<template>
  <TransitionRoot as="template" :show="props.isOpenUploadModal">
    <Dialog
      as="div"
      class="relative z-50"
      @close="props.isOpenUploadModal = false"
    >
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-95 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-50 w-screen overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-0 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-black px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-full max-w-3xl p-4"
            >
              <div
                v-if="displaySkeleton"
                class="bg-gray-100 rounded skeleton p-60 mb-3"
              >
              <p class="text-white">{{buttons}}</p>
                <div
                  class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                >
                  <svg
                    fill="none"
                    class="w-24 h-24 animate-spin text-gray-500"
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      clip-rule="evenodd"
                      d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
                      fill="currentColor"
                      fill-rule="evenodd"
                    />
                  </svg>
                </div>
              </div>

              <!--             
              <button
                type="button"               
                class="block w-full mt-2 py-2.5 px-5 mr-2 text-sm font-medium text-white focus:outline-none  rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-50 focus:ring-4 focus:ring-gray-200"
              >
                Upload ด้วย Gallery ผ่านมือถือ
              </button> -->
              <input
                type="file"
                id="file-prewedding"
                name="file-prewedding"
                accept="image/*"
                multiple
                hidden
              
                @input="onUploadMultiImage($event)"
              />
              <label
                for="file-prewedding"
                class="h-16 px-3 py-2 flex justify-center items-center border border-gray-200 text-white rounded cursor-pointer mr-1 shadow-lg text-sm font-medium text-white focus:outline-none focus:z-50 focus:ring-4 focus:ring-gray-200 border-gray-200"
              >
                <!-- <ArrowUpTrayIcon class="w-5 h-5 mr-2" /> -->
                 <!-- <i class="fa-regular fa-cloud-upload-alt mr-2 text-xl"></i> -->
                 <i class="fa-regular fa-mobile mr-2 text-xl"></i>
                <span>{{buttons?.mobile}} </span>
              </label>



              <button
                @click="$router.push('/wish/desktop/info')"
                type="button"
                class="block w-full mt-6 py-5 px-5 mr-2 text-sm font-medium text-white focus:outline-none rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-50 focus:ring-4 focus:ring-gray-200"
              >
              <i class="fa-solid fa-desktop mr-2 text-xl"></i>

                {{buttons?.computer}}
              </button>

              <button
                @click="close"
                type="button"
                class="block w-full mt-10 py-2.5 px-5 mr-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-50 focus:ring-4 focus:ring-gray-200"
              >
                Close
              </button>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { ref } from "vue";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { CheckIcon } from "@heroicons/vue/24/outline";

const emit = defineEmits([,"closed"]);
const props = defineProps({
  buttons: {type: Object, default: {}},
  onUploadMultiImage:{
    type:Function,    
  },
  isOpenUploadModal: {
    type: Boolean,
    default: false,
  },
});

const close = () => {
  emit("closed");
};
</script>
